<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <!------------ START: Dropzone overlay ------------>
      <div
        class="dropzone d-flex justify-content-center align-items-center"
        @dragleave="onDropzoneDragLeave"
        @dragover.prevent
        @drop.prevent="onDrop"
      >
        <div class="h1 text-white">
          {{
            field.dropZoneLabel
              ? getSnippet(field.dropZoneLabel)
              : $t("formHelper.dropZoneLabel")
          }}
        </div>
      </div>
      <!------------ END: Dropzone overlay ------------>
      <!------------ START: File dropzone ------------>
      <div v-show="!value && !isEdit" class="upload-wrapper text-center">
        <div
          v-show="!field.defaultImage"
          class="upload-dropzone text-muted text-h5 w-100 py-10 cursor-pointer"
          @click="triggerFileInput"
        >
          {{
            field.uploadLabel
              ? getSnippet(field.uploadLabel)
              : $t("formHelper.uploadLabel")
          }}
        </div>
        <div v-show="field.defaultImage">
          <img
            :src="field.defaultImage"
            :alt="$t('formHelper.imageAlt')"
            class="img-fluid"
          />
          <div class="text-right mb-n5">
            <span class="text-muted text-caption">
              ({{ $t("formHelper.default") }})
            </span>
          </div>
        </div>
        <div class="upload-button mt-3">
          <div class="btn btn-primary" @click="triggerFileInput">
            Datei auswählen
          </div>
        </div>
        <input ref="file" type="file" class="d-none" @change="onFileInput" />
      </div>
      <!------------ END: File dropzone ------------>
      <!------------ START: Display value ------------>
      <div v-show="value && !isEdit" class="image-wrapper text-center">
        <perfect-scrollbar
          class="scroll border rounded w-100 text-center p-3"
          style="max-height: 500px"
          :options="scrollbarOptions"
        >
          <img
            :src="src"
            :alt="$t('formHelper.imageAlt')"
            class="img-fluid"
            style="pointer-events: none"
          />
        </perfect-scrollbar>
        <button
          type="button"
          class="btn btn-secondary mt-2"
          @click.prevent="remove"
        >
          {{ $t("formHelper.imageRemove") }}
        </button>
      </div>
      <!------------ END: Display value ------------>
      <!------------ START: Cropper ------------>
      <div v-show="isEdit" class="image-edit">
        <div class="image-edit-image" style="height: 500px">
          <img
            ref="editImage"
            :src="editSrc"
            :alt="$t('formHelper.imageAlt')"
          />
        </div>
        <div class="text-center mt-3">
          <button
            type="button"
            class="btn btn-secondary m-2"
            @click.prevent="cancelEdit"
          >
            {{ $t("formHelper.cancel") }}
          </button>
          <button
            type="submit"
            class="btn btn-primary m-2"
            @click.prevent="saveEdit"
          >
            {{ $t("formHelper.imageCropLabel") }}
          </button>
        </div>
      </div>
      <!------------ END: Cropper ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import $ from "jquery";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";

import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
export default {
  components: { FieldWrapper },
  mixins: [base, eagerValidation],
  props: {},
  data() {
    return {
      file: null,
      isEdit: false,
      cropper: false,
      scrollbarOptions: {
        wheelPropagation: false
      }
    };
  },
  computed: {
    src: function () {
      return this.value;
    },
    editSrc: function () {
      return this.file?.url ?? "";
    }
  },
  watch: {
    isEdit: function () {
      this.handleCropper();
    }
  },
  created() {
    let container = this.field.container
      ? document.getElementById(this.field.container)
      : window;
    container.addEventListener("dragenter", this.onBodyDragEnter);
  },
  mounted() {},
  beforeDestroy() {
    let container = this.field.container
      ? document.getElementById(this.field.container)
      : window;
    container.removeEventListener("dragenter", this.onBodyDragEnter);
  },
  methods: {
    onBodyDragEnter(e) {
      e.preventDefault();
      this.showDropzone(true);
    },
    onDropzoneDragLeave(e) {
      e.preventDefault();
      this.showDropzone(false);
    },
    onDrop(e) {
      // Prevent opening file in new tab
      e.preventDefault();
      // Hide dropzone overlay
      this.showDropzone(false);
      // Check file length
      let fileLength = e.dataTransfer.files.length;
      if (fileLength > 1) {
        // Show error toast and return
        this.$toast.fire({
          icon: "error",
          title: this.$t("formHelper.acceptedFileLength")
        });
        this.value = null;
        return;
      }
      // Assign dropped file to input field
      this.$refs.file.files = e.dataTransfer.files;
      // Get selected file
      let file = e.dataTransfer.files.item(0);
      this.onImageSelected(file);
    },
    showDropzone(state) {
      const body = $("body");
      if (state && !body.hasClass("dragging")) {
        // If state is true and body does not have class "dragging" yet
        body.addClass("dragging");
      } else if (!state && body.hasClass("dragging")) {
        // Else remove class
        body.removeClass("dragging");
      }
    },
    onFileInput() {
      // When file is selected from explorer
      let file = this.$refs.file.files.item(0);
      this.onImageSelected(file);
    },
    onImageSelected(file) {
      // Create file url
      file = this.createFileUrl(file);
      // Set file as edit value
      this.file = file;
      // Start edit mode
      this.isEdit = true;
    },
    triggerFileInput() {
      // Click "select file" button
      this.$refs.file.click();
    },
    saveEdit() {
      let me = this;
      // Deactivate editing
      this.isEdit = false;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      // On success set base64 as value
      reader.onload = function () {
        me.value = reader.result;
      };
      // On error show error message
      reader.onerror = function (error) {
        me.$error(error);
      };
    },
    cancelEdit() {
      // Stop edit mode and remove selected file
      this.isEdit = false;
      this.file = null;
    },
    createFileUrl(file) {
      // Set new file url
      file.url = "";
      let URL = window.URL || window.webkitURL;
      // Create object url
      if (URL && URL.createObjectURL) {
        file.url = URL.createObjectURL(file);
      }
      return file;
    },
    remove() {
      // Set value to null and trigger input event
      this.value = null;
    },
    handleCropper() {
      // If no value is set, return
      if (!this.isEdit) {
        // If cropper exists, destroy
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
        return;
      }
      let me = this;
      this.$nextTick().then(() => {
        // If edit image element is not found, return
        if (!me.$refs.editImage) {
          return;
        }
        // Set cropper options
        let cropperOptions = {
          aspectRatio: this.field.ratio ?? NaN,
          viewMode: 0,
          crop: function () {}, //to show the crop box manually
          minCanvasWidth: 50,
          minCanvasHeight: 50,
          minCropBoxWidth: 150,
          minCropBoxHeight: 150,
          minContainerWidth: 150, //decides the size of image
          minContainerHeight: 150, //decides the size of image
          autoCropArea: 1,
          modal: true, // Show the black modal
          guides: true, // Show the dashed lines for guiding
          center: true, // Show the center indicator for guiding
          highlight: true, // Show the white modal to highlight the crop box
          background: false, // Show the grid background,
          scalable: true,
          rotatable: true,
          checkOrientation: true,
          cropBoxResizable: true,
          dragMode: "move"
        };
        // Set new cropper
        me.cropper = new Cropper(me.$refs.editImage, cropperOptions);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dropzone {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  .dragging & {
    pointer-events: auto;
    opacity: 1;
  }
}
.upload-dropzone {
  border: 1px dashed #b5b5c3;
  border-radius: 0.42rem;
}
</style>
